import React, { useState, useEffect } from 'react';
import SEO from 'gatsby-theme-antv/site/components/Seo';
import { useTranslation } from 'react-i18next';
import { graphql } from 'gatsby';
import 'codemirror/theme/neo.css';
import {
  Card,
  Select,
  Input,
  Form,
  Tooltip,
  Button,
  Radio,
  Divider,
  message,
  Spin,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
// @ts-ignore
import qs from 'qs';
import { UnControlled as CodeMirrorEditor } from 'react-codemirror2';
import styles from '../assets/css/independent.module.less';

// @ts-ignore
import line from '../assets/img/line.png';
// @ts-ignore
import step1 from '../assets/img/1.png';
// @ts-ignore
import step2 from '../assets/img/2.png';
// @ts-ignore
import step3 from '../assets/img/3.png';
// @ts-ignore
import step4 from '../assets/img/4.png';

const md5 = require('js-md5');

const { Option } = Select;

const Indepent = ({ data, location }: { data: any; location: Location }) => {
  const host = location.protocol + '//' + location.host;
  const [form] = Form.useForm();
  const [schema, setSchema] = useState(undefined);
  const [requestUrl, setrequestUrl] = useState('');
  const [param, setparam] = useState('');
  const [response, setresponse] = useState('');
  const [loading, setloading] = useState(false);
  const { t, i18n } = useTranslation();
  const { services } = data.docsMateJson.en.translation;
  const steps = [
    {
      no: 1,
      step_icon: step1,
      title: t('选择接口'),
      desc: t('选择合适的API接口'),
    },
    {
      no: 2,
      step_icon: step2,
      title: t('填写AppKey与AppSecret'),
      desc: t('相关site_id与hash'),
    },
    {
      no: 3,
      step_icon: step3,
      title: t('填写参数'),
      desc: t('填写系统生成的参数表值'),
    },
    {
      no: 4,
      step_icon: step4,
      title: t('测试验证'),
      desc: t('点击测试验证，获取调试信息'),
    },
  ];
  const layout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 17 },
  };

  useEffect(() => {
    const query = location.search && qs.parse(location.search.split('?')[1]);
    // console.log(query);
    form.setFieldsValue({ service: '' });
    if (query.service) {
      const option = services.find((val: any) => val.service === query.service);
      // console.log(option)
      setSchema(option && JSON.parse(option.params));
      form.setFieldsValue({
        service: query.service,
      });
    }
  }, []);
  const onChange = (value: any, option: any) => {
    // console.log(`selected ${value}`, option);
    // form.resetFields();
    setSchema(JSON.parse(option.params));
  };

  const handleRequest = (values: any) => {
    // console.log(values);
    if (!values.params) {
      values.params = {};
    }
    for (let key in values.params) {
      values.params[key] =
        values.params[key] === '' || values.params[key] === null
          ? undefined
          : values.params[key];
    }
    if (values.params.ext) {
      try {
        values.params.ext = JSON.parse(values.params.ext);
      } catch (error) {
        message.error('ext参数格式不正确');
        return;
      }
    }
    const timestamp = parseInt(new Date().getTime() / 1000);
    const md5str = `${values.app_secret}app_key${values.app_key}service${
      values.service
    }timestamp${timestamp}${JSON.stringify(values.params)}${values.app_secret}`;
    // console.log(md5str, md5(md5str));
    const para = {
      app_key: values.app_key,
      sign: md5(md5str).toUpperCase(),
      service: values.service,
      timestamp: timestamp,
    };
    setrequestUrl(`/apis?${qs.stringify(para)}`);
    setparam(JSON.stringify(values.params));
    setloading(true);
    setresponse('');
    window._czc.push([
      '_trackEvent',
      `independent_btnTest_click`,
      'click',
      values.service,
    ]);
    axios
      .post(
        `${host}/apis?${qs.stringify(para)}`,
        JSON.stringify(values.params),
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      .then(function (res) {
        setresponse(JSON.stringify(res.data, null, 2));
        // console.log(res);
        setloading(false);
      })
      .catch(function (error) {
        setresponse(
          JSON.stringify(error.response.data) || JSON.stringify(error, null, 2),
        );
        // console.log(error.response);
        setloading(false);
      });
  };

  const copyText = (value = '') => {
    const copyStr = value;
    const oInput = document.createElement('input');
    oInput.value = copyStr;
    document.body.appendChild(oInput);
    oInput.select();
    const copyResult = document.execCommand('copy');
    document.body.removeChild(oInput);
    if (copyResult) {
      message.success('已复制到粘贴板');
    } else {
      message.error('复制失败');
    }
  };

  const renderForm = (type: string) => {
    switch (type.toLocaleLowerCase()) {
      case 'boolean':
        return (
          <Radio.Group>
            <Radio value={true}>是</Radio>
            <Radio value={false}>否</Radio>
          </Radio.Group>
        );
      case 'object':
        return (
          <Input.TextArea
            style={{ width: 350 }}
            rows={3}
            autoSize
            placeholder="请输入"
          />
        );
      default:
        return <Input style={{ width: 350 }} placeholder="请输入" />;
    }
  };
  return (
    <div className={styles.tool_page}>
      <SEO title={t('多麦开放平台接口调试工具')} lang={i18n.language} />

      <Card
        title={
          <div className={styles.title}>{t('多麦开放平台接口调试工具')}</div>
        }
        style={{ width: 1200 }}
        bordered={false}
      >
        <div className={styles.tips}>
          {t(
            '此工具目的是帮助开发者检测使用多麦联盟开放平台开发者API时发送的请求参数是否正确，设置内容提交后可获取服务器的相关验证结果',
          )}
        </div>
        <div className={styles.content}>
          <img className={styles.line} src={line} alt="" />
          <div className={styles.step_Content}>
            {steps.map((item) => (
              <div className={styles.stepItem} key={item.no}>
                <img src={item.step_icon} alt="" />
                <p className={styles.step_title}>{item.title}</p>
                <p className={styles.step_desc}>{item.desc}</p>
              </div>
            ))}
          </div>
        </div>
        <Spin spinning={loading}>
          <div className={styles.form_content}>
            <div className={styles.form_left}>
              <Form
                {...layout}
                name="basic"
                form={form}
                onFinish={handleRequest}
              >
                <Form.Item
                  label={t('选择接口')}
                  name="service"
                  rules={[{ required: true, message: t('Please enter') }]}
                >
                  <Select
                    showSearch
                    style={{ width: 350 }}
                    placeholder="请输入"
                    optionFilterProp="children"
                    onChange={onChange}
                    filterOption={(input, option) =>
                      option.value.toLowerCase().indexOf(input.toLowerCase()) >=
                        0 ||
                      option.desc.toLowerCase().indexOf(input.toLowerCase()) >=
                        0
                    }
                  >
                    {services.map((val: any) => (
                      <Option
                        value={val.service}
                        key={val.service}
                        desc={val.desc}
                        params={val.params}
                      >
                        <div>{val.desc}</div>
                        <div>{val.service}</div>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="AppKey" required>
                  <Form.Item
                    name="app_key"
                    noStyle
                    rules={[{ required: true, message: '请输入' }]}
                  >
                    <Input style={{ width: 350 }} placeholder="请输入" />
                  </Form.Item>
                  <Tooltip
                    title={
                      <div>
                        site_id
                        <a
                          href="http://open.duomai.cn/zh/docs/site/help/app_config"
                          rel="noreferrer"
                          target="_blank"
                        >
                          查看如何获取
                        </a>
                      </div>
                    }
                  >
                    <InfoCircleOutlined
                      style={{ marginLeft: 8, color: '#999' }}
                    />
                  </Tooltip>
                </Form.Item>
                <Form.Item label="AppSecret" required>
                  <Form.Item
                    name="app_secret"
                    noStyle
                    rules={[{ required: true, message: '请输入' }]}
                  >
                    <Input style={{ width: 350 }} placeholder="请输入" />
                  </Form.Item>
                  <Tooltip
                    title={
                      <div>
                        hash
                        <a
                          href="http://open.duomai.cn/zh/docs/site/help/app_config"
                          rel="noreferrer"
                          target="_blank"
                        >
                          查看如何获取
                        </a>
                      </div>
                    }
                  >
                    <InfoCircleOutlined
                      style={{ marginLeft: 8, color: '#999' }}
                    />
                  </Tooltip>
                </Form.Item>
                {schema && <Divider />}
                {schema &&
                  Object.keys(schema.properties).map((key) => (
                    <Form.Item
                      label={key}
                      key={key}
                      required={
                        schema.required && schema.required.includes(key)
                      }
                    >
                      <Form.Item
                        name={['params', key]}
                        noStyle
                        rules={[
                          {
                            required:
                              schema.required && schema.required.includes(key),
                            message: '请输入',
                          },
                        ]}
                      >
                        {renderForm(schema.properties[key].type)}
                      </Form.Item>
                      <Tooltip title={schema.properties[key].description}>
                        <InfoCircleOutlined
                          style={{ marginLeft: 8, color: '#999' }}
                        />
                      </Tooltip>
                    </Form.Item>
                  ))}
                <Form.Item wrapperCol={{ offset: 7 }}>
                  <Button type="primary" htmlType="submit">
                    {t('测试验证')}
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <div className={styles.query_result}>
              <Card title={t('请求')} size="small" className={styles.request}>
                <CodeMirrorEditor
                  value={`POST ${requestUrl}\nContent-Type: application/json;\nHost: ${host}\n${param}`}
                  options={{
                    mode: 'http',
                    readOnly: true,
                    theme: 'neo',
                    lineWrapping: true,
                    lineNumbers: true,
                    tabSize: 2,
                    styleActiveLine: true, // 当前行背景高亮
                    matchBrackets: true, // 括号匹配
                    autoCloseBrackets: true,
                    autofocus: false,
                    matchTags: {
                      bothTags: true,
                    },
                  }}
                />
              </Card>
              <Card
                title={t('响应')}
                style={{ marginTop: 20 }}
                extra={<a onClick={() => copyText(response)}>{t('复制')}</a>}
                size="small"
                className={styles.request}
              >
                <CodeMirrorEditor
                  value={response}
                  options={{
                    mode: 'javascript',
                    readOnly: true,
                    theme: 'neo',
                    lineWrapping: true,
                    lineNumbers: true,
                    tabSize: 2,
                    styleActiveLine: true, // 当前行背景高亮
                    matchBrackets: true, // 括号匹配
                    autoCloseBrackets: true,
                    autofocus: false,
                    matchTags: {
                      bothTags: true,
                    },
                  }}
                />
              </Card>
            </div>
          </div>
        </Spin>
      </Card>
    </div>
  );
};

// Indepent.noLayout = true;

export default Indepent;

export const query = graphql`
  query {
    docsMateJson {
      en {
        translation {
          services {
            desc
            service
            params
          }
        }
      }
    }
  }
`;
