import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './JoinUs.module.less';

import line from '../assets/img/line.png';
import step1 from '../assets/img/1.png';
import step2 from '../assets/img/2.png';
import step3 from '../assets/img/3.png';
import step4 from '../assets/img/4.png';

const JoinUs: React.FC = () => {
  const { t, i18n } = useTranslation();
  const steps = [
    {
      no: 1,
      step_icon: step1,
      title: t('注册账号'),
      desc: t('快速注册，加入多麦'),
    },
    {
      no: 2,
      step_icon: step2,
      title: t('创建媒体'),
      desc: t('1对1专属媒介服务'),
    },
    {
      no: 3,
      step_icon: step3,
      title: t('获取密钥'),
      desc: t('专用唯一接口hash值'),
    },
    {
      no: 4,
      step_icon: step4,
      title: t('使用API'),
      desc: t('技术接入获取精准信息'),
    },
  ];
  return (
    <div className={styles.join_us}>
      <div className={styles.title}>
        {t('加入多麦联盟，成为我们的共赢伙伴')}
      </div>
      <div className={styles.content}>
        <img className={styles.line} src={line} alt="" />
        <div className={styles.step_Content}>
          {steps.map((item) => (
            <div className={styles.stepItem} key={item.no}>
              <img src={item.step_icon} alt="" />
              <p className={styles.step_title}>{item.title}</p>
              <p className={styles.step_desc}>{item.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default JoinUs;
