import { graphql } from 'gatsby';
import Index from './independent.zh';

export default Index;

export const query = graphql`
  query {
    docsMateJson {
      en {
        translation {
          services {
            desc
            service
            params
          }
        }
      }
    }
  }
`;
