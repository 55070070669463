import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Partner.module.less';

import jd from '../assets/img/jingdong.png';
import nike from '../assets/img/nike.png';
import meidi from '../assets/img/meidi.png';
import microsoft from '../assets/img/Microsoft.png';
import apple from '../assets/img/apple.png';
import resource from '../assets/img/800+.png';
import game from '../assets/img/1688.png';
import meituan from '../assets/img/meituan.png';
import farfetch from '../assets/img/Farfetch.png';
import iqiy from '../assets/img/aiqiyi.png';
import vipshop from '../assets/img/weipinhui.png';
import huawei from '../assets/img/huawei.png';

import unjd from '../assets/img/jingdong-unselect.png';
import unnike from '../assets/img/nike-unselect.png';
import unmeidi from '../assets/img/meidi-unselect.png';
import unmicrosoft from '../assets/img/Microsoft-unselect.png';
import unapple from '../assets/img/apple-unselect.png';
import ungame from '../assets/img/1688-unselect.png';
import unmeituan from '../assets/img/meituan-unselect.png';
import unfarfetch from '../assets/img/Farfetch-unselect.png';
import uniqiy from '../assets/img/aiqiyi-unselect.png';
import unvipshop from '../assets/img/weipinhui-unselect.png';
import unhuawei from '../assets/img/huawei-unselect.png';

const Partner = () => {
  const [startBlinking, setStartBlinking] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', (e: any) => {
      const scrollTop = document.documentElement.scrollTop;
      if (scrollTop >= 700) {
        setStartBlinking(true);
      }
    });
    return () => {
      window.removeEventListener('scroll', function () {});
    };
  });
  const { t, i18n } = useTranslation();
  return (
    <div className={styles.partner}>
      <div className={styles.title}>{t('多麦联盟合作伙伴')}</div>
      <div className={styles.client}>
        <div
          className={`${styles.client_card} ${styles.mar_r} ${styles.mar_b} ${
            startBlinking ? styles.client_card_animation : ''
          }`}
        >
          <img className={styles.front} src={jd} alt="" />
          <img className={styles.back} src={unjd} alt="" />
        </div>
        <div
          className={`${styles.client_card} ${styles.mar_r} ${styles.mar_b} ${
            startBlinking ? styles.client_card_animation : ''
          }`}
        >
          <img className={styles.front} src={meidi} alt="" />
          <img className={styles.back} src={unmeidi} alt="" />
        </div>
        <div
          className={`${styles.client_card} ${styles.mar_r} ${styles.mar_b} ${
            startBlinking ? styles.client_card_animation : ''
          }`}
        >
          <img className={styles.front} src={microsoft} alt="" />
          <img className={styles.back} src={unmicrosoft} alt="" />
        </div>
        <div
          className={`${styles.client_card} ${styles.mar_b} ${
            startBlinking ? styles.client_card_animation : ''
          }`}
        >
          <img className={styles.front} src={huawei} alt="" />
          <img className={styles.back} src={unhuawei} alt="" />
        </div>
        <div
          className={`${styles.client_card} ${styles.mar_r} ${styles.mar_b} ${
            startBlinking ? styles.client_card_animation : ''
          }`}
        >
          <img className={styles.front} src={apple} alt="" />
          <img className={styles.back} src={unapple} alt="" />
        </div>
        <div
          className={`${styles.client_middle_card} ${styles.mar_r} ${styles.mar_b}`}
        >
          <img src={resource} alt="" />
        </div>
        <div
          className={`${styles.client_card} ${styles.mar_b} ${
            startBlinking ? styles.client_card_animation : ''
          }`}
        >
          <img className={styles.front} src={game} alt="" />
          <img className={styles.back} src={ungame} alt="" />
        </div>
        <div
          className={`${styles.client_card} ${styles.mar_r} ${
            startBlinking ? styles.client_card_animation : ''
          }`}
        >
          <img className={styles.front} src={meituan} alt="" />
          <img className={styles.back} src={unmeituan} alt="" />
        </div>
        <div
          className={`${styles.client_card} ${styles.mar_r} ${
            startBlinking ? styles.client_card_animation : ''
          }`}
        >
          <img className={styles.front} src={farfetch} alt="" />
          <img className={styles.back} src={unfarfetch} alt="" />
        </div>
        <div
          className={`${styles.client_card} ${styles.mar_r} ${
            startBlinking ? styles.client_card_animation : ''
          }`}
        >
          <img className={styles.front} src={iqiy} alt="" />
          <img className={styles.back} src={uniqiy} alt="" />
        </div>
        <div
          className={`${styles.client_card} ${
            startBlinking ? styles.client_card_animation : ''
          }`}
        >
          <img className={styles.front} src={vipshop} alt="" />
          <img className={styles.back} src={unvipshop} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Partner;
