import { graphql } from 'gatsby';
import Index from './index.zh';

export default Index;

export const query = graphql`
  query {
    site {
      siteMetadata {
        openMapConfig {
          logo
          title
          api_list {
            name {
              zh
              en
            }
            slug
          }
        }
      }
    }
  }
`;
