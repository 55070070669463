// @ts-ignore
import React from 'react';
import SEO from 'gatsby-theme-antv/site/components/Seo';
import { useTranslation } from 'react-i18next';
import Partner from '../components/Partner';
import OpenMap from '../components/OpenMap';
import JoinUs from '../components/JoinUs';
import banner from '../assets/img/banner.png';
import { graphql } from 'gatsby';

export default function IndexPage({ data }: { data: any }) {
  const { t, i18n } = useTranslation();
  return (
    <>
      <SEO title={t('多麦联盟开放接口平台')} lang={i18n.language} />
      <img src={banner} style={{ width: '100%' }} alt="" />
      <OpenMap openMapConfig={data.site.siteMetadata.openMapConfig} />
      <Partner />
      <JoinUs />
    </>
  );
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        openMapConfig {
          logo
          title
          api_list {
            name {
              zh
              en
            }
            slug
          }
        }
      }
    }
  }
`;
