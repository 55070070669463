import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './OpenMap.module.less';

import arrow from '../assets/img/arrow.png';

interface OpenMapProps {
  openMapConfig: any;
}

const OpenMap: React.FC<OpenMapProps> = ({ openMapConfig }) => {
  const [active, setactive] = useState('商家推广计划查询');
  const { t, i18n } = useTranslation();

  return (
    <div className={styles.open_map}>
      <div className={styles.title}>
        {t('多麦联盟推广开发者的能力开放地图')}
      </div>
      <div
        className={styles.content}
        onMouseEnter={() => setactive('')}
        onMouseLeave={() =>
          setactive(openMapConfig[0].api_list[0].name[i18n.language])
        }
      >
        {openMapConfig.map((item: any, index: number) => (
          <div className={styles.guide} key={item.title}>
            <img src={require(`../assets/img/${item.logo}.png`)} alt="" />
            <div className={styles.cg_title}>{item.title}</div>
            <ul className={styles.api_list}>
              {item.api_list.map((val: any, key: number) => (
                <li
                  className={`${styles.api_item} ${
                    active === val.name[i18n.language]
                      ? styles.api_item_active
                      : ''
                  }`}
                  key={val.name[i18n.language]}
                  onMouseEnter={() => setactive(val.name[i18n.language])}
                  onMouseLeave={() => setactive('')}
                >
                  <a
                    href={`/${i18n.language}/${val.slug}`}
                    onClick={() =>
                      window._czc.push([
                        '_trackEvent',
                        `home_homePage_apiMap${index + 1}-${key + 1}_click`,
                        'click',
                      ])
                    }
                  >
                    <span>{val.name[i18n.language]}</span>
                    <img src={arrow} className={styles.arrow} alt="" />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};
export default OpenMap;
